<template>
  <footer id="footer" class="container-fluid bg-gray py-5">
    <div class="container my-5 py-3">
        <div class="row">
            <div class="col-xl-4 me-5 text-start mb-4">
                <h4>Budite u toku sa najnovijim vestima vezanim za Euro Petrol.</h4>
                <p>Naš bilten je fokusiran na najnovije informacije od Euro Petrola: vesti i novosti, događaji, EP proizvodi, akcije, radionice i još mnogo toga.</p>
                <a href="#newsletter" class="btn btn-main rounded-0 text-light d-inline-flex align-items-center gap-2 py-2" aria-label="Newsletter">Zapratite nas <i class="ri-arrow-right-line"></i></a>
            </div>
            <div class="col-xl d-flex flex-column flex-md-row flex-wrap justify-content-between p-0 gap-2 gap-md-0">
                <div class="d-flex-inline text-start mx-3 footer-elem px-4 py-2 p-md-0">
                    <p class="footer-title mb-0 d-flex align-items-center" data-bs-toggle="collapse" href="#euroPetrolFooter" role="button" aria-expanded="false" aria-controls="euroPetrolFooter">Euro Petrol<i class="ri-arrow-drop-down-line ms-auto d-inline d-md-none"></i></p>
                    <div class="collapse d-md-block" id="euroPetrolFooter">
                        <hr class="d-md-none mt-3 mb-1"/>
                        <div class="d-flex flex-column gap-3">
                            <a class="mt-3" href="/#about-euro-petrol">O kompaniji</a>
                            <a href="/najnovije-vesti">Vesti i događaji</a>
                            <a href="/kontakt-informacije">Kontakt</a>
                            <a class="mb-3" href="/otvorene-pozicije">Karijera</a>
                        </div>
                    </div>
                </div>

                <div class="d-flex-inline text-start mx-3 footer-elem px-4 py-2 p-md-0">
                    <p class="footer-title mb-0 d-flex align-items-center" data-bs-toggle="collapse" href="#aboutUsFooter" role="button" aria-expanded="false" aria-controls="aboutUsFooter">Korisne informacije<i class="ri-arrow-drop-down-line ms-auto d-inline d-md-none"></i></p>
                    <div class="collapse d-md-block" id="aboutUsFooter">
                        <hr class="d-md-none mt-3 mb-1"/>
                        <div class="d-flex flex-column gap-3">
                            <a class="mt-3" href="/politika-kvaliteta">Politika kvaliteta</a>
                            <a href="https://www.amss.org.rs/na-putu/stanje-na-putu/strana/mapa" target="_blank" rel="noopener">Stanje na putevima</a>
                            <a href="https://www.amss.org.rs/na-putu/stanje-na-putu/strana/video" target="_blank" rel="noopener">Stanje na putevima - <span class="color-main">video</span></a>
                            <a href="https://www.amss.org.rs/na-putu/stanje-na-putu/strana/vreme" target="_blank" rel="noopener">Vremenska prognoza</a>
                            <a href="/zastita-podataka">Zaštita podataka</a>
                            <a class="mb-3" href="/pravila-i-uslovi-koriscenja">O kolačićima</a>
                        </div>
                    </div>
                </div>

                <div class="d-flex-inline text-start mx-3 footer-elem px-4 py-2 p-md-0">
                    <p class="footer-title mb-0 d-flex align-items-center" data-bs-toggle="collapse" href="#socialFooter" role="button" aria-expanded="false" aria-controls="socialFooter">Društvene mreže<i class="ri-arrow-drop-down-line ms-auto d-inline d-md-none"></i></p>
                    <div class="collapse d-md-block" id="socialFooter">
                        <hr class="d-md-none mt-3 mb-1"/>
                        <div class="d-flex flex-column gap-3">
                            <a class="mt-3" href="https://www.facebook.com/EuroPetrolSrbija" target="_blank" rel="noopener">Facebook</a>
                            <a href="https://www.instagram.com/euro.petrol/" target="_blank" rel="noopener">Instagram</a>
                            <a class="mb-3" href="https://www.youtube.com/user/europetrolsubotica" target="_blank" rel="noopener">YouTube</a>
                        </div>
                    </div>
                </div>

            </div>

            <div class="d-flex-inline my-5">

                <hr>

                <div class="d-flex justify-content-center gap-2 flex-wrap">
                    <img width="125" height="125" src="/img/ISO/ZC E.jpg" alt="ZC ISO STANDARD">
                    <img width="125" height="125" src="/img/ISO/ZC O.jpg" alt="ZC ISO STANDARD">
                    <img width="125" height="125" src="/img/ISO/ZC Q.jpg" alt="ZC ISO STANDARD">
                    <img width="125" height="125" src="/img/ISO/IQNET RCMark_PosCMYK.png" alt="IQNET">
                </div>

                <hr>
                <div class="row justify-content-between py-2 align-items-center">
                    <small class="my-2 col-lg-4 footer-bottom-left">Euro Petrol © {{currentYear}} Sva prava zadržana.</small>
                    <small class="my-2 col-lg-4 footer-bottom-center">Dizajn i kod: <a href="https://www.icbtech.rs/" target="_blank" rel="noopener"><u>ICBTECH</u></a></small>
                    <small class="my-2 col-lg-4 footer-bottom-right cursor-pointer"><a href="#" class="d-flex align-items-center">Izabrani jezik: Srpski <i class="ri-arrow-right-line color-main"></i></a></small>
                </div>
            </div>

        </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  }
};
</script>

<style scoped>
.bg-gray{
    background-color: #FBFBFB;
}
.footer-title{
    font-size: 20px;
    font-weight: 700;
}
footer a{
    color: inherit;
    text-decoration: none;
}
footer a:hover{
    color: var(--color-main);
}

.footer-title[aria-expanded="true"] .ri-arrow-drop-down-line {
  transform: rotate(180deg);
}

@media (max-width: 768px) {
    .footer-elem {
        border: 1px solid #e0e0e0 !important;
    }

    .footer-title{
        font-size: 17px;
        font-weight: 600;
    }

    .footer-bottom-left{
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.footer-bottom-center {
    flex-basis: auto;
    width: inherit;
    margin-left: auto;
    margin-right: auto;
}

.footer-bottom-left {
    flex-basis: auto;
    width: inherit;
    margin-left: 0;
}

.footer-bottom-right {
    flex-basis: auto;
    width: inherit;
    margin-right: 0;
}
</style>
